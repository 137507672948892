import React, { useState } from 'react'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import { getUserContainer } from './container/user-module'
import { ILoggedUserService } from './modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY } from './modules/users'
import { useTranslation } from 'react-i18next'
import styles from './Login.module.css'
import { toModel } from 'modules/users/models/LoggedUserDTO'
import { LoggedUser } from 'modules/users/models/LoggedUser'
import { Alert, Box } from '@mui/material'
import CustomButton from './components/form/CustomButton'
import logo from 'assets/images/logo.png'
import CustomTextField from './components/form/CustomTextField'
import { useNavigate } from 'react-router-dom'
import { ROUTE_HOME, ROUTE_PASSWORD_RECOVER } from './routes/routes-constants'

const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)

export type LoginProps = {
  onLogin: (user: LoggedUser) => void
}

export default function Login(props: LoginProps) {
  const [login, setLogin] = useState('')
  const [password, setPassword] = useState('')
  const [hasError, setHasError] = useState(false)
  const navigate = useNavigate()

  const handlerLogin = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    doLogin()
  }

  function recoveryPassword() {
    navigate(ROUTE_PASSWORD_RECOVER)
  }

  const doLogin = () => {
    setHasError(false)
    loggedUserService.login({ login, password }).subscribe((res) => {
      if (!res) {
        setHasError(true)
        return
      } else {
        props.onLogin(toModel(res))
        navigate(ROUTE_HOME)
      }
    })
  }

  return (
    <div className={styles.background}>
      <Container component="div" className={styles.mainContainer}>
        <div className={styles.container}>
          <img src={logo} alt="Logo Murcia Insitu" className={styles.logo} />
          <form className={styles.formContainer} noValidate onSubmit={handlerLogin}>
            <CustomTextField
              fullWidth={true}
              id="email"
              onChange={(e) => setLogin(e.target.value)}
              label={'Email'}
            />

            <CustomTextField
              containerStyle={{ marginTop: 10 }}
              type={'password'}
              fullWidth={true}
              id="password"
              onChange={(e) => setPassword(e.target.value)}
              label={'Password'}
            />
            <Box display={'flex'} className={styles.button}>
              <CustomButton fullWidth={true} onClick={() => doLogin()} color={'primary'}>
                {'LOGIN'}
              </CustomButton>
            </Box>
            {/** 
            <Typography
              style={{ marginTop: -20 }}
              onClick={() => recoveryPassword()}
              className={styles.forgottenPasswordText}
            >
              Olvidó la contraseña
            </Typography>
            */}
            {hasError && (
              <Alert style={{ marginTop: 20 }} severity="error">
                Usuario o contraseña no válidos
              </Alert>
            )}
          </form>
        </div>
      </Container>
    </div>
  )
}
