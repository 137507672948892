import { BoolQueryParam } from '../../../common/api/Query'
import { v4 as uuidv4 } from 'uuid'
import { UserGender } from '../enums/UserGender'

export interface UserDTO {
  id?: string
  firstName?: string
  lastName?: string
  username?: string
  gender?: number
  birthDate?: Date
  dni?: string
  phone?: string
  code: string
  email?: string
  roles?: string[]
  password?: string
}

export interface RecoverPasswordDTO {
  email: string
}

export function emptyUserDTO() {
  return {
    id: uuidv4(),
    firstName: '',
    lastName: '',
    username: '',
    gender: UserGender.Female,
    birthDate: new Date(),
    dni: '',
    phone: '',
    email: '',
    related: [],
    roles: [],
    password: '',
    code: ''
  }
}

export class User {
  private readonly _id: string
  private readonly _firstName: string
  private readonly _lastName: string
  private readonly _username: string
  private readonly _gender: number
  private readonly _birthDate: Date
  private readonly _dni: string
  private readonly _phone: string
  private readonly _email: string
  private readonly _password: string
  private readonly _roles: string[]
  private readonly _code: string

  constructor(p: UserDTO) {
    this._id = p.id || uuidv4()
    this._firstName = p.firstName || ''
    this._lastName = p.lastName || ''
    this._username = p.username || ''
    this._gender = p.gender || 0
    this._birthDate = p.birthDate || new Date()
    this._dni = p.dni || ''
    this._phone = p.phone || ''
    this._email = p.email || ''
    this._roles = p.roles || []
    this._code = p.code
    this._password = p.password || ''
  }

  get id(): string {
    return this._id
  }

  get firstName(): string {
    return this._firstName
  }

  get code(): string {
    return this._code
  }

  get lastName(): string {
    return this._lastName
  }

  get username(): string {
    return this._username
  }

  get gender(): number {
    return this._gender
  }

  get birthDate(): Date {
    return this._birthDate
  }

  get dni(): string {
    return this._dni
  }

  get phone(): string {
    return this._phone
  }

  get email(): string {
    return this._email
  }

  get roles(): string[] {
    return this._roles
  }

  dateAsString(): string {
    return this._birthDate.toDateString()
  }

  get password(): string {
    return this._password
  }

  toDTO(): UserDTO {
    return {
      id: this.id,
      firstName: this.firstName,
      lastName: this.lastName,
      username: this.username,
      gender: this.gender,
      birthDate: this.birthDate,
      dni: this.dni,
      phone: this.phone,
      email: this.email,
      roles: this.roles,
      password: this.password,
      code: this.code
    }
  }
}

export interface UserQuery {
  id: string
  ids: string[]
  username: string
  firstName: string
  lastName: string
  role: string
  isActive: BoolQueryParam
  dni: string
  email: string
  phone: string
  roles: string[]
  allFields: string
  birthDate: Date
}

export function toModel(d: UserDTO): User {
  return new User(d)
}

export function fromModel(d: User): UserDTO {
  return {
    id: d.id,
    firstName: d.firstName,
    lastName: d.lastName,
    username: d.username,
    gender: d.gender,
    dni: d.dni,
    phone: d.phone,
    birthDate: d.birthDate,
    email: d.email,
    roles: d.roles,
    password: d.password,
    code: d.code
  }
}
