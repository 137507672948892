import { Button, ButtonProps, CircularProgress } from '@mui/material'
import { useMediaQuery } from '@mui/material'

export type ColorType = 'primary' | 'secondary' | undefined
interface CustomButtonProps extends ButtonProps {
  color?: ColorType
  loading?: boolean
}

function CustomButton({ color, loading, disabled, children, ...rest }: CustomButtonProps) {
  const isMobile = useMediaQuery('(max-width:767.98px)')
  function getColor(color: ColorType) {
    if (color === 'primary') return 'var(--color-red_insitu)'
    return 'var(--color-darkgray-theme)'
  }

  return (
    <Button
      disabled={loading || disabled}
      {...rest}
      style={{
        boxShadow: 'none',
        fontSize: isMobile ? 18 : 20,
        minWidth: isMobile ? 130 : 250,
        height: 45,
        borderRadius: 18,
        backgroundColor: getColor(color),
      }}
      variant={'contained'}
    >
      {(loading && <CircularProgress size={'small'} style={{ color: 'white' }} />) || children}
    </Button>
  )
}

export default CustomButton
